@import 'styles/_functions';

.wrapper {
  .inner {
    overflow: hidden;
    display: flex;

    .overflow {
      display: flex;

      > * {
        flex-shrink: 0;
      }
    }

    @include mobile {
      width: mobile-vw(375px);
    }

    @include desktop {
      position: sticky;
      --height: #{desktop-vw(440px)};
      top: calc((100vh - var(--height)) / 2);
    }

    @include mobile {
      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        > * {
          margin-bottom: mobile-vw(32px);
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .inner {
    @include desktop {
      top: calc((100vh - var(--height)) / 8) !important;
    }
  }
}