@import 'styles/_functions';

$intro-in: 1500ms;
$intro-out: 1500ms;

.wrapper {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;

  @include mobile {
    display: none;
  }

  &.out {
    transition: transform $intro-out var(--ease-out-expo);
    transform: translate3d(0, -100%, 0);
    transition-delay: calc($intro-in + 0ms);
  }

  .start {
    transform: translate3d(0, calc(var(--index) * 5% + 100%), 0);
  }

  .relative {
    padding: desktop-vw(30px) desktop-vw(32.5px);

    transition: transform $intro-out var(--ease-out-expo);
    transform: translate3d(0, desktop-vh(850px), 0);
    transition-delay: calc($intro-in + 0ms);

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .show {
    transition: transform $intro-in var(--ease-out-expo);
    transition-delay: calc(var(--index) * 75ms);
    transform: translate3d(0, 0, 0);
  }
}

.translate {
  @include desktop {
    transform: translate3d(0, -100%, 0);
    transition: transform $intro-out var(--ease-out-expo);
  }
}

.mobile {
  @include mobile {
    transform: translate3d(0, -105%, 0);
  }
}
