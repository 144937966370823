@import 'styles/_functions';

.footer {
  // background-color: var(--theme-primary);
  color: var(--theme-secondary);
  padding: mobile-vw(20px) 0;
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @include desktop {
    height: 100vh;
    padding: desktop-vw(40px) 0;
  }

  .top {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: mobile-vw(20px);
    align-items: center;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    @include desktop {
      padding-bottom: desktop-vw(40px);
    }

    .first-line {
      margin: auto 0;

      @include desktop {
        margin: 0;
        align-self: start;
        grid-column: 1 / span 8;
      }
    }

    .shameless-plug {
      align-self: start;
      display: flex;
      flex-direction: column;

      @include desktop {
        text-align: end;
        grid-column: 9 / span 4;
      }

      p:first-child {
        margin-bottom: mobile-vw(8px);

        @include desktop {
          margin-bottom: desktop-vw(8px);
        }
      }
    }

    .cta {
      // this CTA is only rendered on desktop
      position: absolute;
      left: 0;
      bottom: desktop-vw(40px);
    }

    .last-line {
      // this line is only rendered on desktop
      text-align: end;
      align-self: end;
      grid-column: 2 / -1;
    }
  }

  .bottom {
    width: var(--layout-width);
    margin: 0 auto;

    @include mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .links {
      @include desktop {
        .link:not(:first-child) {
          margin: 0;
          margin-left: desktop-vw(32px);
        }
      }

      @include mobile {
        .link {
          display: block;
          margin-bottom: mobile-vw(16px);
        }
        .link:last-child {
          margin-bottom: mobile-vw(30px);
        }
      }
    }

    .icon {
      vertical-align: bottom;
      transform: scale(0.8);
    }

    .tm {
      @include mobile {
        text-align: end;
        align-self: end;
      }

      span {
        vertical-align: text-top;
      }
    }

    .cta {
      // this CTA is only rendered on mobile
      grid-column: 1 / -1;
      margin-top: mobile-vw(12px);
    }
    .ceshi1 {
      grid-column: 1 / -1;
      margin-top: mobile-vw(10px);
      text-align: center;
      span{
        font-size: mobile-vw(18px);
        color: rgba(53, 53, 66, 1);
        border-bottom: 1px solid rgba(53, 53, 66, 1);
      }
    }
  }
  .ceshi {
    position: absolute;
    line-height: 200%;
    left: desktop-vw(220px);
    bottom: desktop-vw(48px);
    p{
      font-size: desktop-vw(30px);
      color: rgba(53, 53, 66, 1);
      border-bottom: 1px solid rgba(53, 53, 66, 1);
    }
  }
}
