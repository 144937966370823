@import 'styles/_functions';

.home main {
  > *:not(.canvas) {
    position: relative;
    // pointer-events: none;
  }
}

.canvas {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100vw;
    width: 200vw;
    background: radial-gradient(var(--myBlue), var(--myBlue-transparent) 70%);
    // background: linear-gradient(to top, var(--pink), var(--pink-transparent));
    transform: translateX(-50%) translateY(50vh);
    opacity: 0.5;
  }
}

$delay-intro-bottom: 150ms;
$delay-intro-top: 450ms;
$intro-duration: 2050ms;

.hide-text {
  overflow: hidden;

  @include desktop {
    > * {
      transform: translate3d(0, 200%, 0);
    }
  }
}

.show-text {
  @include desktop {
    > * {
      transform: translate3d(0, 0, 0);
      transition: transform $intro-duration var(--ease-out-expo);
      transition-delay: $delay-intro-top;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: calc(100 * var(--vh));
  margin-bottom: mobile-vw(160px);
  position: relative;

  @include mobile {
    padding-bottom: mobile-vw(16px);

    > div {
      grid-gap: mobile-vw(11px);
    }
  }

  @include desktop {
    height: 100vh;
    margin-bottom: desktop-vw(320px);
  }

  .title {
    grid-column: 1 / -1;
    margin-top: mobile-vw(30px);
    width: var(--layout-width);

    @include mobile {
      height: mobile-vw(85px);
    }

    @include desktop {
      margin-top: desktop-vw(30px);
      position: absolute;
      top: 0;
      left: 2.5%;
    }
  }

  .icon {
    width: mobile-vw(44px);
    height: mobile-vw(39px);

    @include desktop {
      position: relative;
      top: desktop-vw(395px);
      width: desktop-vw(44px);
      height: desktop-vw(39px);
      transform: translate3d(0, 100%, 0);
      opacity: 0;
    }

    &.show {
      @include desktop {
        transition: transform $intro-duration var(--ease-out-expo),
          opacity $intro-duration var(--ease-out-expo);
        transition-delay: $delay-intro-top;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .sub {
    display: flex;
    flex-direction: column;
    text-align: end;
    grid-column: 2 / -1;

    @include desktop {
      position: relative;
      top: desktop-vw(325px);
      grid-column: 6 / span 7;
    }

    .subtitle {
      margin-bottom: mobile-vw(8px);

      @include desktop {
        margin-bottom: desktop-vw(12px);
      }
    }

    .tm {
      color: var(--grey);

      span {
        vertical-align: text-top;
      }
    }
  }

  .bottom {
    width: 100%;
    overflow: hidden;

    @include desktop {
      padding-bottom: desktop-vw(40px);
      margin-left: 42px;
    }

    // Scroll Hint is only rendered on desktop
    .scroll-hint {
      position: relative;
      grid-column: 1 / span 2;
      transition: opacity 0.6s var(--ease-in-out-quad),
        transform 0.6s var(--ease-in-out-quad);

      .text {
        font-family: var(--font-primary);
        font-weight: 900;
        text-transform: uppercase;
        font-stretch: condensed;
        line-height: 100%;
        letter-spacing: -0.01em;

        @include desktop {
          padding-left: desktop-vw(16px);
          font-size: desktop-vw(24px);
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: desktop-vw(2px);
        background-color: var(--blue);
        animation: scale 4s infinite;
        animation-play-state: paused;
        transform-origin: 50% 0;
        transform: scale(0);
      }

      @keyframes scale {
        50% {
          transform: scaleY(1.5);
        }
      }

      &.show {
        &::before {
          animation-play-state: running;
        }
      }

      &.hide {
        opacity: 0;
        transform: translateY(20%);

        &::before {
          animation-play-state: paused;
        }
      }
    }

    .description {
      grid-column: 1 / -1;

      @include desktop {
        grid-column: 3 / span 5;
      }
    }

    .cta {
      grid-column: 1 / -1;
      text-align: end;

      &.in {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      @include desktop {
        transition: transform calc($intro-duration - 200ms) var(--ease-out-expo),
          opacity $intro-duration var(--ease-out-expo);
        grid-column: 10 / -1;
        opacity: 0.5;
        transform: translate3d(0, 100%, 0);
      }
    }
  }
}

.why {
  margin-bottom: mobile-vw(160px);

  @include desktop {
    margin-bottom: desktop-vw(640px);
  }

  .sticky {
    grid-column: 1 / -1;
    color: var(--blue);
    @include desktop {
      position: sticky;
      top: 33%;
      align-self: start;
      border-left: 4px solid var(--blue);
      grid-column: 3 / span 4;
      padding: desktop-vw(24px) desktop-vw(32px);
    }
  }

  .features {
    grid-column: 1 / -1;
    
    @include desktop {
      grid-column: 7 / -1;
      margin-top: desktop-vw(256px);
    }
  }

  .feature {
    &:not(:first-child) {
      margin-top: mobile-vw(120px);
    }

    @include desktop {
      width: columns(4);

      &:not(:first-child) {
        margin-top: desktop-vw(400px);
      }
    }

    .title {
      color: var(--blue);
      margin-bottom: mobile-vw(16px);

      @include desktop {
        margin-bottom: desktop-vw(24px);
      }
    }
  }
}

.rethink {
  .pre {
    margin-bottom: mobile-vw(160px);

    @include desktop {
      margin-bottom: desktop-vw(240px);
    }

    .highlight {
      @include desktop {
        border-left: 4px solid var(--blue);
        grid-column: 3 / span 4;
        top: desktop-vw(64px);
        padding: desktop-vw(24px) 0 desktop-vw(24px) desktop-vw(32px);
      }
    }

    .introtitle {
      color: var(--blue);
      @include mobile {
        width: auto;
      }
    }

    .comparison {
      grid-column: 1 / -1;

      @include desktop {
        grid-column: 7 / span 4;
        margin-top: desktop-vw(256px);
      }
    }
  }

  .cards {
    margin-bottom: mobile-vw(160px);

    @include desktop {
      margin-bottom: desktop-vw(400px);
    }

    .sticky {
      @include desktop {
        color: var(--blue);
        position: relative;
        width: desktop-vw(400px);
        top: 0;
        left: desktop-vw(270px);
        align-self: start;
        border-left: 4px solid var(--blue);
        padding: desktop-vw(24px) desktop-vw(32px);
        margin-bottom: desktop-vw(230px);
      }
      @include mobile {
        position: relative;
        top: mobile-vw(-30px);
        width: mobile-vw(200px);
        margin-left: mobile-vw(15.5px);
      }
    }
    .card {
      @include desktop {
        &:first-child {
          // margin-left: var(--layout-margin);
          // margin-left: 100vw;
          // margin-left: calc((var(--layout-columns-gap) * 4) + columns(5));
          margin-left: calc(
            columns(6) + var(--layout-margin) + var(--layout-columns-gap)
          );
        }

        &:not(:last-child) {
          margin-right: calc(
            var(--layout-columns-gap) * 2 + var(--layout-column-width)
          );
        }

        &:last-child {
          margin-right: calc(
            columns(2) + var(--layout-margin) + var(--layout-columns-gap)
          );
        }
      }
    }
  }
}

.solution {
  position: relative;

  color: var(--white);
  height: 500vh;

  @include desktop {
    height: 1000vh;
  }

  .inner {
    position: sticky;
    top: 0;
    margin: 0 auto;
    overflow: hidden;
    height: calc(100 * var(--vh, 1vh));

    &::after {
      content: '';
      background-color: currentColor;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%) scaleX(var(--progress2));
      top: 0;
    }

    @include desktop {
      height: 100vh;
    }

    .zoom {
      align-self: start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      transform: scale(calc(1 + (var(--progress1)) * 3));
      padding: mobile-vh(16px);

      @include desktop {
        padding: desktop-vh(40px) var(--layout-margin);
      }
    }
  }

  .first {
    transform: translateY(calc(var(--progress1) * -100%));
  }

  .second {
    text-align: end;
  }

  .enter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-54.2%, -50%) scale(calc((var(--progress1) * 18.2)));
    transform-origin: 50% calc(50% - (var(--progress1) * 27%));
    opacity: calc(var(--progress1) * 2);
    text-align: center;
    // will-change: transform;

    @include desktop {
      transform: translate(-50%, -50%) scale(calc((var(--progress1) * 8.5)));
      transform-origin: 50% calc(50% - (var(--progress1) * 25%));
    }
  }

  .link:hover {
    text-decoration: underline;
  }
}

.featuring {
  position: relative;
  padding-bottom: mobile-vw(160px);
  color: var(--theme-secondary);

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: mobile-vw(150px);
    position: absolute;
    background: linear-gradient(
      to bottom,
      var(--theme-primary),
      var(--theme-primary-transparent)
    );

    @include desktop {
      height: desktop-vw(150px);
    }
  }

  @include desktop {
    padding: desktop-vw(40px) 0;
    padding-bottom: desktop-vw(320px);
  }

  .inner {
    position: relative;
  }

  .intro {
    padding-bottom: mobile-vw(160px);

    @include desktop {
      padding-bottom: desktop-vw(440px);
    }
  }
}

.in-use {
  padding-bottom: mobile-vw(160px);
  // background-color: var(--theme-primary);
  color: var(--theme-secondary);

  @include desktop {
    padding-bottom: desktop-vw(400px);
  }

  .title {
    grid-column: 1 / -1;
    @include desktop {
      grid-column: 1 / span 5;
    }
  }

  .list {
    grid-column: 1 / -1;

    @include desktop {
      grid-column: 6 / span 7;
    }
  }
}

.temp {
  height: 100vh;
  opacity: 0.95;
  display: flex;
  align-items: center;
}

.preline {
  white-space: pre-line;
  @include desktop {
    font-size: desktop-vw(22px) !important;
  }
}

.ceshi {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop {
    position: absolute;
    right: desktop-vw(380px);
    bottom: desktop-vw(55px)
  }
  p {
    font-size: desktop-vw(20px);
    color: #fff;
    border-bottom: 1px solid #fff;
    @include mobile{
      font-size: mobile-vw(18px);
    }
  }
}

.userTitle {
  color: var(--blue);

  @include desktop {
    font-size: desktop-vw(64px);
    margin-right: desktop-vw(84px);
    white-space: nowrap;
    margin-left: calc(
        columns(6) + var(--layout-margin) + var(--layout-columns-gap)
    );
  }

  @include mobile {
    font-size: desktop-vw(80px);
    width: inherit;
    margin-left: mobile-vw(28px);
  }
  &:nth-child(3) {
    @include desktop {
      margin-left: calc(
          columns(1) + var(--layout-margin) + var(--layout-columns-gap)
      );
    }
  }
}

.starTtile {
  @include desktop {
    margin-left: calc(
      columns(1) + var(--layout-margin) + var(--layout-columns-gap)
  );
  }
  @include mobile {
    font-size: desktop-vw(80px);
    width: inherit;
    margin-bottom: mobile-vw(32px);
    margin-left: mobile-vw(14px);
  }
}

.outList {
  display: flex;
  @include mobile {
    flex-wrap: wrap;
  }
  .carImg {
    @include desktop {
      width: auto;
      height: 100%;
      margin-top: desktop-vw(20px);
    }
    @include mobile {
      width: mobile-vw(170px);
      height: 100%;
      margin-left: mobile-vw(10px);
      margin-top: mobile-vw(2px);
    }
  }
  .starsImg {
    @include desktop {
      width: auto;
      height: 100%;
    }
    @include mobile {
     padding: 0 5px;
    }
  }
}

.listBox {
 @include mobile {
   width: 25%;
    //margin-left: desktop-vw(30px);

  }
}

.listBox2 {
  @include desktop {
    margin-left: 40px;
  }
  @include mobile {
    width: 50%;
   }
 }

.title-color {
  color: #000;
}

.ins {
  display: flex;
}

.insItem {
  @include desktop {
    position: absolute;
    width: 120%;
    left: desktop-vw(430px);
    top:desktop-vw(-10px);
  }
  @include mobile {
    width: 95%;
    margin-top: mobile-vw(20px);
    margin-bottom: mobile-vw(30px);
  }
}

.sticTitle {
  color: var(--pink) !important;
  border-color: var(--pink) !important;
  @include desktop {
    margin-bottom: desktop-vw(480px) !important;
  }
  @include mobile {
    width: inherit !important;
    margin-top: mobile-vw(20px);
  }
}

.stickyImg {
  color: var(--blue);
  @include desktop {
    position: relative;
    width: desktop-vw(400px);
    top: desktop-vw(100px);
    left: desktop-vw(270px);
    align-self: start;
    border-left: 4px solid var(--blue);
    padding: desktop-vw(24px) desktop-vw(32px);
    margin-bottom: desktop-vw(50px);
  }
  @include mobile {
    position: relative;
    top: mobile-vw(15px);
    width: inherit;
    margin-left: mobile-vw(15.5px);
  }
}

.starsImg {
  @include desktop {
    position: absolute;
    left: desktop-vw(540px);
    top: desktop-vw(0px);
    height: auto;
    max-width: desktop-vw(500px);
  }
  @include mobile {
   margin-top: mobile-vw(20px);
   width: 90%;
  }
}

.overTitle {
  @include desktop {
    font-size: desktop-vw(36px) !important;
  }
}

@media (max-width: 1300px) {
  .outList {
    .carImg {
      @include desktop {
        height: 550px;
      }
    }
  }
}