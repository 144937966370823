@import './_reset.scss';
@import './_colors.scss';
@import './_easings.scss';
@import './_functions.scss';
@import './_layout.scss';
@import './_utils.scss';
@import './_font-style.scss';
@import './_themes.scss';
@import './_scroll.scss';

:root {
  --header-height: #{mobile-vw(58px)};

  @include desktop {
    --header-height: #{desktop-vw(98px)};
  }
}

html {
  font-weight: bolder;
  font-family: var(--font-primary);
  scrollbar-width: thin;

  &.nav {
    overflow: hidden;
  }
}

html.lenis-stopped {
  overflow: hidden;
}

html,
body {
  overscroll-behavior-y: none;
}

body {
  min-height: 100vh;
}

// custom cursor
// html.has-custom-cursor {
//   &,
//   * {
//     &,
//     &::before,
//     &::after {
//       cursor: none !important;
//     }
//   }
// }

a,
button,
input,
label,
textarea,
select {
  color: inherit;
  cursor: pointer;
}

*::selection {
  background-color: var(--theme-contrast);
  color: var(--theme-primary);
}

svg.icon {
  path[fill],
  rect[fill],
  circle[fill] {
    fill: currentColor;
  }

  path[stroke],
  rect[stroke],
  circle[stroke] {
    stroke: currentColor;
  }
}

.intro {
  overflow: hidden;
}
