@import 'styles/_functions';

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--theme-secondary);
  border: 1px solid;
  aspect-ratio: 1 / 1;
  padding: mobile-vw(24px);
  width: mobile-vw(343px);
  background-color: var(--background);
  backdrop-filter: blur(5px);

  &.inverted {
    color: var(--theme-primary);
    background-color: var(--theme-secondary);
  }

  @include desktop {
    width: columns(4);
    padding: desktop-vw(24px);
  }

  .number {
    color: var(--theme-contrast);
    font-stretch: condensed;
    font-weight: 900;
    line-height: 86%;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
    font-size: mobile-vw(36px);
    white-space: pre-line;

    @include desktop {
      font-size: desktop-vw(56px);
    }
  }

  .title {
    font-stretch: expanded;
    line-height: 100%;
    letter-spacing: -0.01em;
    font-size: mobile-vw(14px);
    margin-bottom: 8px;

    @include desktop {
      font-size: desktop-vw(16px);
    }
  }

  .text {
    font-stretch: expanded;
    line-height: 1.1;
    letter-spacing: -0.01em;
    font-size: mobile-vw(12px);
    white-space: pre-line;

    @include desktop {
      font-size: desktop-vw(12px);
    }

    span {
      font-stretch: normal;
      font-weight: 600;
    }
  }

  .small {}

  .large {
    font-size: mobile-vw(12px);

    @include desktop {
      font-size: desktop-vw(18px);
    }
  }
}
