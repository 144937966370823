@import 'styles/_functions';

// hide native scrollbar

.scrollbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0;

  @media (hover: none) {
    display: none;
  }

  .inner {
    width: 100%;
    height: desktop-vw(4px);
    position: relative;
    background-color: var(--pink);
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
}
