.junk {
  z-index: 9394;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--myBlue);
  font-size: 14px;

  .tip {
    position: absolute;
    top: 8px;
    right: 16px;
    color: #fff;
  }

  .logo {
    width: 60vw;
    height: auto;
    margin-bottom: 16px;
  }

  .text {
    text-align: center;
  }
}