.aspect-ratio {
  position: relative;
  padding-bottom: calc(100% / var(--aspect-ratio));
  width: 100%;
  height: 0;

  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.hidden-overflow {
  overflow: hidden;
}

.relative {
  position: relative;
}

.hide-on-desktop {
  @include desktop {
    display: none !important;
  }
}

.hide-on-mobile {
  @include mobile {
    display: none !important;
  }
}

html:not(.has-scroll-smooth) {
  .hide-on-native-scroll {
    display: none !important;
  }
}

html.has-scroll-smooth {
  .hide-on-smooth-scroll {
    display: none !important;
  }
}
