@import 'styles/_functions';

.bg {
  z-index: 1500;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    height: 60vh;
    transform: translateX(8px);
  }

  .btn {
    padding: desktop-vh(4px) desktop-vw(36px);
    @include mobile {
      padding: desktop-vh(4px) desktop-vw(120px);
    }
  }
}