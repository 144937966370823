@import 'styles/_functions';

.controller {
  display: inline-block;
  z-index: 1000;
  position: fixed !important;
  bottom: 0;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 0 0 4px 4px;

  @include desktop {
    transition: all .3s linear;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .icon {
    display: block;
  }
  .language {
    width: 20px;
    height: 20px;
  }
}

.links {
  padding: 4px;
  .link {
    display: block;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .iicon {
    vertical-align: bottom;
    transform: scale(0.8);
  }
}