@import 'styles/_functions';

.button {
  display: flex;
  grid-template-columns: desktop-vw(48px) 1fr;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  text-transform: uppercase;
  position: relative;
  line-height: 200%;
  font-size: mobile-vw(14px);
  // color: var(--black);
  overflow: hidden;
  text-align: center;

  @include desktop {
    font-size: desktop-vw(14px);
    letter-spacing: -0.01em;
  }

  .text {
    position: relative;
    z-index: 10;
    flex-grow: 1;
    // margin-left: mobile-vw(48px);

    .visible,
    .hidden {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @include desktop {
        padding: 0 desktop-vw(24px);
        //   margin-left: desktop-vw(24px);
      }
    }

    .visible {
      position: relative;
      transition: transform 600ms var(--ease-out-expo),
        opacity 600ms var(--ease-out-expo);
      transform: scaleY(1);
      transform-origin: bottom;
      color: #fff;
    }

    .hidden {
      transform-origin: top;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      // transform: translateY(30%);
      transform: scaleY(0);
      opacity: 0;
      transition: transform 600ms var(--ease-out-expo),
        opacity 400ms var(--ease-out-expo);

      .arrow {
        transform: translate(-50%, 50%);
      }
    }
  }

  &::after,
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
  }

  &:before {
    background-color: var(--red);
  }

  &:after {
    transform: scaleY(0);
    background-color: var(--theme-secondary);
    z-index: 0;
    transition: transform 600ms var(--ease-out-expo);
    transform-origin: top;
  }

  .icon {
    display: flex;
    aspect-ratio: 1 / 1;
    transition: border-color 600ms var(--ease-out-expo);
    width: mobile-vw(48px);
    z-index: 10;
    padding: mobile-vw(12px);
    background-color: rgba(53, 53, 66, 1);
    border: 1px solid var(--red);

    @include desktop {
      width: desktop-vw(48px);
      padding: desktop-vw(12px);
    }

    svg {
      color: rgba(53, 53, 66, 1);
      margin: auto;
      transition: color 600ms var(--ease-out-expo);
      background-color: rgba(53, 53, 66, 1);
      width: mobile-vw(24px);
      height: mobile-vw(24px);

      @include desktop {
        width: desktop-vw(24px);
        height: desktop-vw(24px);
      }
    }
  }

  .arrow {
    position: relative;
    z-index: 10;
    aspect-ratio: 1 / 1;
    width: mobile-vw(10px);
    margin-left: mobile-vw(16px);
    // margin-right: mobile-vw(25px);
    transition: transform 600ms var(--ease-out-expo);

    @include desktop {
      width: desktop-vw(10px);
      margin-left: desktop-vw(16px);
      // margin-right: desktop-vw(25px);
    }
  }

  @include hover {
    &:hover {
      color: var(--theme-primary);

      &::after {
        transform: scaleY(1);
        transform-origin: bottom;
      }

      .text {
        .visible {
          // transform: translateY(-100%);
          transform: scaleY(0);
          opacity: 0;
          transform-origin: top;

          .arrow {
            transform: translate(50%, -50%);
          }
        }

        .hidden {
          // transform: translateY(0%);
          transform: scaleY(1);
          transform-origin: bottom;
          opacity: 1;

          .arrow {
            transform: translate(0, 0);
          }
        }
      }

      .icon {
        border: 1px solid var(--theme-secondary);

        // svg {
        //   color: var(--theme-contrast);
        // }
      }
    }
  }
}
