@import 'styles/_functions';

.wrapper {

  .inner {
    overflow: hidden;
    display: flex;

    .list {
      //display: flex;
      //flex-wrap: wrap;
      img {
        margin-top: desktop-vw(38px);
        margin-right: desktop-vw(24px);
        cursor: pointer;
        @include mobile {
          width: 100%;
          height: auto;
          margin-top: mobile-vw(5px);
          margin-left: mobile-vw(2px);
          padding-right: mobile-vw(8px);
        }
      }
      img:first-child{
        margin-top: desktop-vw(20px);
        @include mobile {
          width: 100%;
          height: auto;
          margin-top: mobile-vw(5px);
          margin-left: mobile-vw(2px);
        }
      }
    }

    .item {
      display: flex;
      align-items: center;

      .defaultImg {
        width: desktop-vw(172px);
        height: desktop-vw(64px);
      }

       .avatar {
        width: desktop-vw(40px);
        height: desktop-vw(40px);
        border-radius: 50%;
         margin-right: desktop-vw(8px);
       }

      @include desktop {
        width: desktop-vw(100px);
      }
    }

    .overflow {
      display: flex;

      > * {
        flex-shrink: 0;
      }
    }

    @include mobile {
      width: 100%;

      .avatar {
        width: mobile-vw(40px);
        height: mobile-vw(40px);
        border-radius: 50%;
        margin-right: mobile-vw(8px);
      }
    }

    @include desktop {
      position: sticky;
      //--height: #{desktop-vw(440px)};
      top: calc((100vh - var(--height)) / 2);
    }

    @include mobile {
      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        > * {
          margin-bottom: mobile-vw(32px);
        }
      }
    }
  }
}