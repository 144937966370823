@import 'styles/_functions';

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  pointer-events: none;
  overflow: hidden;

  @media (hover: none) {
    display: none;
  }

  .cursor {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: mobile-vw(2px) solid var(--pink);
    width: 40px;
    height: 40px;
    opacity: 0.4;
    transition: transform 600ms var(--ease-out-expo);

    @include desktop {
      border: desktop-vw(2px) solid var(--pink);
    }

    &.pointer {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }
}
