.h1 {
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  line-height: 86%;
  font-size: mobile-vw(56px);

  @include desktop {
    font-size: desktop-vw(160px);
    line-height: 85%;
  }

  &.vh {
    font-size: mobile-vh(56px);

    @include desktop {
      font-size: desktop-vh(160px);
    }
  }
}

.h2 {
  font-weight: 900;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  line-height: 86%;
  font-size: mobile-vw(56px);

  @include desktop {
    font-size: desktop-vw(96px);
    line-height: 83%;
  }

  &.vh {
    font-size: mobile-vh(56px);

    @include desktop {
      font-size: desktop-vh(96px);
    }
  }
}

.h3 {
  font-stretch: expanded;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 100%;
  font-size: mobile-vw(20px);
  letter-spacing: -0.01em;

  @include desktop {
    letter-spacing: 0.01em;
    font-size: desktop-vw(52px);
  }

  &.vh {
    font-size: mobile-vh(20px);

    @include desktop {
      font-size: desktop-vh(52px);
    }
  }
}

.h4 {
  font-stretch: expanded;
  font-weight: 900;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  line-height: 100%;
  font-size: mobile-vw(20px);

  @include desktop {
    font-size: desktop-vw(28px);
    letter-spacing: -0.01em;
  }
}

.p-l {
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.03em;
  font-size: mobile-vw(32px);

  @include desktop {
    font-size: desktop-vw(64px);
  }
}

.p {
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 125%;
  font-size: mobile-vw(16px);

  @include desktop {
    font-size: desktop-vw(18px);
    line-height: 133%;
  }

  &.bold {
    font-weight: 600;
    line-height: 125%;

    @include desktop {
      line-height: 133%;
    }
  }
}

.p-s {
  font-weight: 600;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-size: mobile-vw(14px);

  @include desktop {
    font-size: desktop-vw(14px);
    line-height: 114%;
  }
}

.p-xs {
  font-weight: 600;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-size: mobile-vw(12px);

  @include desktop {
    font-size: desktop-vw(12px);
    line-height: 113%;
  }
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.contrast {
  color: var(--theme-contrast);
}

.myConstrast {
  color: var(--blue);
}

.grey {
  color: var(--grey);
}
