@import 'styles/_functions';

.item {
  // border-bottom: desktop-vw(1px) solid transparent;

  position: relative;

  .inner {
    padding: mobile-vw(16px) 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
    }

    @include desktop {
      align-items: center;
      padding: desktop-vw(16px) 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: desktop-vw(1px);
    background-color: var(--grey);
    bottom: 0;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #353542;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 600ms var(--ease-out-expo);
  }

  @include mobile {
    flex-direction: column;

    &::before {
      content: none;
    }
  }

  .title {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    transition: transform 600ms var(--ease-out-expo);
    // will-change: transform;
    overflow: hidden;
    @include mobile {
      align-items: flex-start;
    }
    .text {
      font-weight: 700;
      line-height: 100%;
      font-size: mobile-vw(16px);
      letter-spacing: -0.03em;
      white-space: pre-wrap;
      color: #2F2f2F;

      @include mobile {
        margin-bottom: mobile-vw(8px);
        margin-top: mobile-vw(8px);
      }

      @include desktop {
        line-height: 114%;
        font-size: desktop-vw(16px);
      }
    }
    .arrow {
      aspect-ratio: 1 / 1;
      margin-left: auto;
      width: mobile-vw(19px);

      @include desktop {
        transform: translate(-20%, 20%);
        opacity: 0;
        margin-left: desktop-vw(24px);
        width: desktop-vw(19px);
      }
    }
  }
  .text_context {
    font-weight: 500;
    line-height: 100%;
    font-size: mobile-vw(12px);
    letter-spacing: -0.03em;
    white-space: pre-wrap;
    color: #464646;
    @include mobile {
      margin-bottom: mobile-vw(8px);
    }

    @include desktop {
      line-height: 120%;
      font-size: desktop-vw(11px);
      margin-top: desktop-vw(5px)
    }
  }
  .source {
    font-weight: 700;
    transition: transform 600ms var(--ease-out-expo);
    line-height: 100%;
    letter-spacing: -0.03em;
    font-size: mobile-vw(20px);
    will-change: transform;

    @include desktop {
      text-align: left;
      line-height: 114%;
      font-size: desktop-vw(25px);
    }
  }


  @include hover {
    &:hover {
      color: #fff;
      &::before {
        transform: scaleY(1);
        transform-origin: bottom;
      }

      .title {
        transform: translateX(desktop-vw(-16px));
        .arrow {
          transition: opacity 600ms var(--ease-out-expo),
            transform 600ms var(--ease-out-expo);
          opacity: 1;
          transform: translate(0, 0);
          transition-delay: 100ms;
        }
        .text {
          color: #fff;
        }
      }

      .source {
        transform: translateX(desktop-vw(16px));       
      }
      .text_context {
        color: #fff;
      }
    }
  }

  //animation

  @include desktop {
    display: inline-block;
    width: 100%;
    transition: transform 1.2s var(--ease-out-expo);
    transition-delay: calc(100ms * var(--i));

    &::after {
      transition: transform 1.2s var(--ease-out-expo);
      transform-origin: right;
      transition-delay: calc(100ms * var(--i));
    }

    .source {
      overflow: hidden;
    }

    .text,
    .source > * {
      transition: transform 1.2s var(--ease-out-expo);
      display: block;
      transition-delay: calc(100ms * var(--i));
    }

    &:not(.visible) {
      transform: translateY(100%);

      &::after {
        transform: scaleX(0);
      }

      .text,
      .source > * {
        transform: translateY(100%);
      }
    }
  }
}
