@import 'styles/_functions';

.adchat {
  position: absolute;
  bottom: 60px;
  
  @include mobile {
    bottom: 16px;
  }

  .btn {
    margin-top: 16px;
    padding: 4px 12px;
    user-select: none;
    touch-action: none;
  }
}

.outer {
  position: absolute;
  bottom: 40px;
  left: 40px;
  opacity: 0;
  transform: scale(0.8);
  transition: all .3s linear;

  @include desktop {
    bottom: 120px;
    left: 100px;
  }

  .indicator {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1px;
    height: 10px;
  }
  
  .line {
    position: absolute;
    opacity: 1;
    width: 2px;
    height: 6vh;
    margin-top: -12vh;
    transform-origin: 50% 100%;
    background: white;

    @include mobile {
      height: 6vw;
    }
  }
}

.show {
  opacity: 1;
  transform: scale(1);
}
