@import 'styles/_functions';

.features {
  height: 1600vh;

  @include desktop {
    min-height: desktop-vw(1310px);
  }

  .card {
    --d: 100vh;

    position: absolute;
    will-change: transform;
    transition-duration: 1.2s;
    transition-property: opacity, transform;
    transition-timing-function: var(--ease-out-expo);
    // transform: translate3d(0, calc(var(--progress) * -1 * var(--d)), 0);
    // transform: translateY(calc(var(--progress) * -1 * var(--d)));

    @include mobile {
      @for $i from 0 through 8 {
        &:nth-child(#{$i + 1}) {
          top: calc(
            (
                (
                    (100 * var(--vh, 1vh)) -
                      #{mobile-vw(440px)} -
                      (var(--layout-margin))
                  ) /
                  8
              ) *
              $i
          );
        }
      }
    }

    @include desktop {
      @for $i from 0 through 8 {
        &:nth-child(#{$i + 1}) {
          top: calc(
            (
                (var(--d) - #{desktop-vw(440px)} - (2 * var(--layout-margin))) /
                  8
              ) *
              $i
          );
          left: calc(
            ((100vw - #{desktop-vw(440px)} - (2 * var(--layout-margin))) / 8) *
              $i
          );
        }
      }
    }

    &:not(.current) {
      transform: translate3d(100%, 100%, 0);
      opacity: 0;
    }
  }

  .title {
    text-align: end;
    padding-bottom: var(--layout-margin);

    @include desktop {
      padding: 0;
      position: absolute;
      right: var(--layout-margin);
    }
  }
}

.sticky {
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: var(--layout-margin);

  @include desktop {
  }

  > * {
    position: relative;
  }
}
