@use 'sass:color';

// Each color filled here will create a css variable exposed globally

$colors: (
  'white': rgb(239, 239, 239),
  'grey': rgb(176, 176, 176),
  'black': rgb(0, 0, 0),
  'pink': rgba(255, 35, 75, 1),
  'blue': rgba(71, 90, 255, 1),
  'red': rgba(255, 35, 75, 1),
  'myColor': rgba(53, 53, 66, 1),
  'myBlue': rgba(53,53,66,1),
);

:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
    // for safari use case: https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
    --#{$name}-transparent: #{color.change($color, $alpha: 0)};
  }
}
